<template>
  <LoginBaseTemplate :title="$t('client_area')">
    <label class="mb-2 block">{{ $t("email") }}</label>
    <input
      v-model.trim="credentials.email"
      type="text"
      class="p-2 mb-2 md:p-3 sm:mb-5"
      @keyup.enter="login()"
    />
    <label class="mb-2 block">{{ $t("password") }}</label>
    <Password
      v-model.trim="credentials.password"
      toggle-mask
      class="mb-2 sm:mb-5 w-full"
      :feedback="false"
      @keyup.enter="login()" 
    />
    <button 
      type="button"
      class="p-button p-component justify-content-center"
      @click="login()"
    >
      {{ $t("enter") }}
    </button>
    <InlineMessage v-if="messageError" severity="error">
      {{ $t(messageError) }}
    </InlineMessage>
    <ProgressBar v-if="isLoading" mode="indeterminate" />
    <p class="message text-sm text-center">
      <a @click="goToAccountRecovery">{{ $t("i_forgot_access_data") }}</a>
    </p>
  </LoginBaseTemplate>
</template>

<script>
import Password from "primevue/password";
import InlineMessage from "primevue/inlinemessage";
import LoginBaseTemplate from "./LoginBaseTemplate.vue";

export default {
  components: { LoginBaseTemplate, InlineMessage, Password },
  extends: LoginBaseTemplate,
  data() {
    return {
      credentials: {
        email: "",
        password: "",
      },
      messageError: null,
      isLoading: false,
    };
  },
  methods: {
    login() {
      if (!this.credentials.email || !this.credentials.password) {
        this.messageError = "email_or_password_is_required";
        return;
      }
      this.messageError = null;
      this.isLoading = true;
      this.$root.axiosSetAuth(null, false);
      this.$root
        .apiPostUnauthenticated(this.$root.api.login, this.credentials)
        .then((response) => {
          this.$store.commit("setToken", response.data.data);
          this.$root.axiosSetAuth(response.data.data, false);
          try {
            let payload = JSON.parse(atob(response.data.data.split(".")[1]));
            this.$store.commit("setAllowedModules", payload.roles);
            this.$store.commit("setHasBalcaoMais", payload.hasBalcaoMais);
            this.$store.commit(
              "setPendingClientRgpdToken",
              payload.pendingClientRgpdToken
            );
          } catch (e) {
            return null;
          }
          this.isLoading = false;

          const urlParams = new URLSearchParams(window.location.search);
          const next = urlParams.get("next");
          document.title = "Área de Cliente - Balcão do Emigrante";
          if (next && next !== "/") {
            this.$router.push(next).catch(() => {});
          } else {
            this.$router.push({ name: "dashboard" }).catch(() => {});
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.messageError = error.response.data.msg_code;
          if (this.messageError == "rgpd_is_required") {
            let params = { email: this.credentials.email };
            this.$root.apiGet(this.$root.api.loginRgpd, { params })
              .then((response) => {
                if (response.data.data.token) {
                  this.$router.push({ name: "rgpd", params: { token: response.data.data.token } }).catch(() => { });
                }
              });
          }
        });
    },
    goToAccountRecovery() {
      this.$router.push({ name: "account-recovery" }).catch(() => {});
    },
  },
};
</script>
