<template>
  <div>
    <ul class="layout-menu">
      <li>
        <router-link
          :to="{ name: 'dashboard' }"
          class="p-ripple"
          @click="routerChanged"
        >
          <i class="layout-menuitem-icon pi pi-fw pi-home" />
          <span class="layout-menuitem-text">{{ $t("dashboard") }}</span>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'processes' }"
          :class="{
            'router-link-exact-active': $route.meta.parent === 'process',
          }"
          @click="routerChanged"
        >
          <i class="layout-menuitem-icon pi pi-fw pi-cog" />
          <span class="layout-menuitem-text">{{ $t("my_processes") }}</span>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'file-manager' }"
          class="p-ripple"
          @click="routerChanged"
        >
          <i class="layout-menuitem-icon pi pi-fw pi-file" />
          <span class="layout-menuitem-text">{{ $t("my_files") }}</span>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'services' }"
          :class="{
            'router-link-exact-active': $route.meta.parent === 'service',
          }"
          @click="routerChanged"
        >
          <i class="layout-menuitem-icon pi pi-fw pi-check-square" />
          <span class="layout-menuitem-text">{{ $t("my_services") }}</span>
        </router-link>
      </li>
      <template v-if="$root.user.hasBalcaoMais">
        <li>
          <router-link
            :to="{ name: 'webinars' }"
            :class="{
              'router-link-exact-active': $route.meta.parent === 'webinars',
            }"
            @click="routerChanged"
          >
            <i class="layout-menuitem-icon pi pi-fw pi-video" />
            <span class="layout-menuitem-text">{{ $t("webinars") }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'bussolas' }"
            class="p-ripple"
            @click="routerChanged"
          >
            <i class="layout-menuitem-icon pi pi-fw pi-book" />
            <span class="layout-menuitem-text">
              {{ $t("journal_bussola") }}
            </span>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'noticias' }"
            class="p-ripple"
            @click="routerChanged"
          >
            <i class="layout-menuitem-icon pi pi-fw pi-globe" />
            <span class="layout-menuitem-text">
              {{ $t("news") }}
            </span>
          </router-link>
        </li>
      </template>
    </ul>
    <ul v-if="cardImageUrl" class="-ml-4 mr-3">
      <img
        loading="lazy"
        :src="cardImageUrl"
        class="w-full"
        alt=""
        style="border-radius: 8px"
      />
    </ul>
    <ul class="contacts">
      <Contacts v-if="contacts" :contacts="contacts" />
    </ul>
  </div>
</template>

<script>
import Contacts from "./Contacts.vue";

export default {
  components: { Contacts },
  props: {
    contacts: { type: Object, default: null },
  },
  emits: ["router-changed"],
  data() {
    return {
      showSmNews: false,
      cardImageUrl: "",
      blobUrl: null,
    };
  },
  watch: {
    "$root.user.cardImageUrl": {
      immediate: true,
      handler() {
        this.updateCardImageUrl();
      },
    },
  },
  methods: {
    routerChanged() {
      this.$emit("router-changed");
    },
    updateCardImageUrl() {
      if (this.$root.user.cardImageUrl) {
        this.blobUrl = null;
        const filename = this.$root.user.cardImageUrl
          .split("/")
          .pop()
          .split(".")[0];

        this.$root
          .apiGetFile(this.$root.getApiFileName(filename))
          .then((response) => {
            if (response) {
              this.cardImageUrl = URL.createObjectURL(response.data);
            }
          });
      } else {
        this.cardImageUrl = "";
      }
    },
  },
};
</script>
