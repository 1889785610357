<template>
  <div>
    <!-- <CampaignFinancialCourse v-if="$root.user.hasBalcaoMais" class="mb-4" /> -->
    <!-- <CampaignReturnToPortugal v-if="$root.user.hasBalcaoMais" class="mb-4" /> -->
    <div class="bl-bussola-medal">
      <ShowLastBussola v-if="$root.user.hasBalcaoMais" />
      <ListMedals />
      <ShowAgenda v-if="$root.user.hasBalcaoMais" />
    </div>
    <div class="bl-news">
      <News />
    </div> 
  </div>
</template>

<script setup>
import { defineAsyncComponent } from "vue";

const News = defineAsyncComponent(() => import("../news/News.vue"));
const ShowAgenda = defineAsyncComponent(() => import("./ShowAgenda.vue"));
const ShowLastBussola = defineAsyncComponent(() =>
  import("../bussola/ShowLastBussola.vue")
);
const ListMedals = defineAsyncComponent(() =>
  import("../medals/ListMedals.vue")
);
// const ListMuralPosts = defineAsyncComponent(() => import("../posts/ListMuralPosts.vue"));
// import CampaignReturnToPortugal from './campaign/CampaignReturnToPortugal.vue';
// import CampaignFinancialCourse from './campaign/CampaignFinancialCourse.vue';
</script>
